import { gql } from '@apollo/client';
import Fragments from '../fragments';

export const CONNECT_ACCOUNT = gql`
  mutation connect($input: CreatePayoutAccountInput!) {
    connectAccount(input: $input) {
      ...connectStep
    }
  }
  ${Fragments.connectStep}
`;

export const ADD_EXTERNAL_ACCOUNT = gql`
  mutation addExternalAccount($input: CreatePaymentAccountInput!) {
    addExternalAccount(input: $input) {
      ...externalAccountBreif
    }
  }
  ${Fragments.externalAccountBreif}
`;

export const DELETE_EXTERNAL_ACCOUNT = gql`
  mutation deleteExternalAccount($organisation: String!, $token: String!) {
    deleteExternalAccount(organisation: $organisation, token: $token) {
      id
      deleted
    }
  }
`;

export const REQUEST_PAYOUT = gql`
  mutation requestPayout($input: CreatePayoutRequestInput!) {
    requestPayout(input: $input) {
      id
      amount
      currency
      description
      failure_code
      # account
      created_by {
        name
      }
      created_at
      arrival_date
    }
  }
`;

export const SUBSCRIBE_TO_ORGANISATION = gql`
  mutation subscribeToOrganisation($slug:String!){
    subscribeToOrganisation(slug:$slug){
      id
      name
      email
      organisation {
        id
        slug
        name
      }
    }
  }
`;

export const ADD_TEAM_MEMBER = gql`
  mutation addTeamMember($input: NewTeamMemberInput!) {
    addTeamMember(input: $input) {
      id
      name
      email
      role
      organisation {
        id
        name
      }
      user {
        id
        name
      }
    }
  }
`;

export const EDIT_TEAM_MEMBER = gql`
  mutation updateTeamMember($memberId: ID!, $input: UpdateTeamMemberInput!) {
    updateTeamMember(memberId: $memberId, input: $input) {
      id
    }
  }
`;

export const DELETE_TEAM_MEMBER = gql`
  mutation deleteTeamMember($memberId: ID!) {
    deleteTeamMember(memberId: $memberId) {
      id
    }
  }
`;
